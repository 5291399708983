<template>
  <div id="page">
    <p>My Page</p>
  </div>
</template>

<script>
export default {
  name: "page"
}
</script>

<style scoped>

</style>